<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">Consider the following reaction:</p>

      <p class="pl-14 mb-5">
        <stemble-latex content="$\ce{A(g) + B(g) -> C(g)}$" />
      </p>

      <p class="mb-5">
        After
        <number-value :value="time" unit="\text{s,}" />
        the concentration of
        <stemble-latex content="$\text{C}$" />
        is found to be
        <number-value :value="concentration" unit="\text{mol/L.}" />
        Determine the average rate of reaction for the production of
        <stemble-latex content="$\text{C.}$" />
      </p>

      <calculation-input
        v-model="inputs.rate"
        class="mb-2"
        prepend-text="$\text{Rate:}$"
        append-text="$\text{M/s}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question362',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        rate: null,
      },
    };
  },
  computed: {
    time() {
      return this.taskState.getValueBySymbol('time').content;
    },
    concentration() {
      return this.taskState.getValueBySymbol('concentration').content;
    },
  },
};
</script>
